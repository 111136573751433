.App {
  text-align: center;
}

.logo {
  height: 6rem;
  pointer-events: none;
}

.app-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-camera{
  height: 300px;
  width: 300px;
  background-color: #FFF;
}

.image-preview{
  width: 320px;
}

.btn-basic{
  width: 100%;
  height: 60px;
  padding: 15px 20px;
  background: linear-gradient(34deg, rgba(214,20,25,1) 28%, rgba(140,6,7,1) 78%);
  color: #FFF;
  font-size: 1.2rem;
  letter-spacing: 1px;
  font-weight: 700;
  transition: 400ms;
  border: none;
}

.btn-basic:disabled{
  opacity: 0.5;
}

.App-link {
  color: #61dafb;
}

.divCamera {
  width: fitContent;
  position: relative;
}

.cardBorder {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border: 2px solid #ffffffcc;
  box-shadow: 0 0 34px 0 black;
  border-radius: 50%;
  max-width: 600px;
  width: 100%;
  height: 90%;
  cursor: pointer;
}

.cardBorder:hover {
    background: #ffffff22;
}

@media screen and (max-width: 768px){
  .cardBorder {
    max-width: 300px;
  }
}

