.App {
  text-align: center;
}

.btn-basic{
  width: 100%;
  height: 60px;
  padding: 15px 20px;
  background: linear-gradient(34deg, rgba(214,20,25,1) 28%, rgba(140,6,7,1) 78%);
  color: #FFF;
  font-size: 1.2rem;
  letter-spacing: 1px;
  font-weight: 700;
  transition: 400ms;
  border: none;
}

.btn-basic:disabled{
  opacity: 0.5;
}

.rosto-inical{
  width: 50%;
}

.rostos{
  width: 50%;
}

li{
  text-align: justify;
  margin-bottom: 10px;
}

.ReactModal__Content{
  inset: 0px !important;
}

@media screen and (max-width: 576px){
  .rostos{
    width: 100%;
  }
}
